import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../assets/d-logo.svg";
import logo from "../assets/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import "../login.css";
import "../main.css";
import { loginRequestBody } from "../request/requestBodies";
import { validateLoginForm as ValidateLoginForm } from "../request/validationFunctions";
import AuthService, { encryptData } from "../services/authService";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import roles from "../request/role";
import checkMailboxIcon from "../assets/icons/Check-mailbox-icon.png";
import userIcon from "../assets/icons/user.png";
import externalIntegration from "../services/externalIntegration";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showUnverifiedPopup, setShowUnverifiedPopup] = useState(false);
  const [showInvalidLoginPopup, setShowInvalidLoginPopup] = useState(false);
  const [invalidLoginCount, setInvalidLoginCount] = useState(0);
  const [msg, setMsg] = useState("");
  const validateForm = ValidateLoginForm(email, password);

  useEffect(() => {
    if (invalidLoginCount >= 5 && !showInvalidLoginPopup) {
      navigate("/Contact");
    }
  }, [invalidLoginCount, showInvalidLoginPopup]);

  useEffect(() => {
    if (errors.status) {
      let errorMessage = "";
      if (errors.status) {
        errorMessage = errors?.response?.data?.data?.["invalid request"];
      }
      if (errors.status && errorMessage === '') {
        errorMessage = errors?.response?.data?.description;
      }
      setMsg(errorMessage);
      setShowInvalidLoginPopup(true);
      console.log(errorMessage);
    }
  }, [errors]);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    const newErrors = ValidateLoginForm(email, password);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
      return;
    }
    try {
      if (captchaVerified || window.location.hostname.includes('localhost')) {
        const apiResponse = await AuthService.login(email.toLowerCase(), password, captchaValue)
          .then(async (res) => {
            console.log(res);
            const encryptedRole = await encryptData(res.data.role);
            const validTillMillis = res.data.validTill;
            localStorage.setItem("role", encryptedRole);
            localStorage.setItem("email", res.data.email);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem(
              "validTill",
              new Date(validTillMillis).toISOString()
            );

            if (res.data.jwtToken) {
              localStorage.setItem("token", res.data.jwtToken);
              if (res.data.role === roles.SUPERADMIN) {
                navigate("/ReportConfiguration");
              }
              if (res.data.role === roles.ADMIN) {
                navigate("/ReportConfiguration");
              }
              if (res.data.role === roles.MEMBER) {
                window.location.href = "/Dashboards";
              }
            }
          })
          .catch(async (errors) => {
            console.log(errors);
            if (errors.status === 403) {
              setShowUnverifiedPopup(true);
            }
            if (errors.status === 401) {
              setShowInvalidLoginPopup(true);
              setInvalidLoginCount((prevCount) => prevCount + 1);
            }
            if (errors.status === 423) {
              setMsg(
                "Account locked due to too many failed login attempts. Please try again after 30 minutes."
              );
              setShowInvalidLoginPopup(true);
            }
            setErrors(errors);
          })
          .finally(() => {
            setLoader(false);
          });
      } else {
        setLoader(false);
        toast.error("Please submit captcha!", {
          autoClose: 2000,
          position: "top-right",
        });
      }
    } catch (errors) {
      alert(
        "Please check your email & password. If you are unable to log in, kindly contact the administrator for assistance."
      );
      console.error("Login failed:", errors);
    }
    // }
  };

  const onCaptchaChange = (value) => {
    console.log("recaptcha");
    setCaptchaVerified(!!value); // set to true if captcha is verified
    if (!!value) {
      setCaptchaValue(value);
    }
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseUnverifiedPopup = () => {
    setShowUnverifiedPopup(false);
  };

  const handleResendLink = () => {
    externalIntegration
      .generateLink(email)
      .then((res) => {
        toast.success("Verification link sent successfully", {
          position: "top-right",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.error(
          "Failed to send verification link! Please try again later",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
      });
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <ToastContainer />
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={6} className="d-flex flex-column align-items-center">
            <Container fluid className="pt-4">
              <Row className="g-0">
                <Col xs={12}>
                  <div className="logo  px-0 px-sm-5 navlogo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="/home"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container fluid className="my-auto py-5">
              <Row className="g-0">
                {/* <Col  xs={11} md={11} lg={11} xl={10} xxl={11} className="mx-auto"> */}
                <Col xs={12} className="mx-auto px-0 px-sm-5">
                  <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">Hi, Welcome!</h1>
                    <Form id="loginForm" onSubmit={handleSubmit}>
                      <Form.Group controlId="emailAddress">
                        <Form.Label className="fw-500">Email</Form.Label>
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type="email"
                            style={{ paddingRight: "25px" }}
                            className="bg-white"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!!errors.email}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                          <span className="icon-inside text-muted">
                            <i className="fas fa-envelope"></i>
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="loginPassword">
                        <Form.Label className="fw-500">Password</Form.Label>
                        <div className="mb-0 icon-group icon-group-end">
                          <Form.Control
                            type={!showConfirmPassword ? "text" : "password"}
                            className="form-control-lg bg-white"
                            placeholder="Password"
                            style={{ paddingRight: "25px" }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={!!errors.password}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                          <span
                            className="icon-inside text-muted"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <i
                              className={`fas ${
                                showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </Form.Group>

                      <Row className="my-0">
                        <Col className="text-end">
                          <a
                            href="/ForgotPassword"
                            className="forgot text-danger"
                          >
                            Forgot Password?
                          </a>
                        </Col>
                      </Row>
                      <Form.Group
                        controlId="formCaptcha"
                        className="captcha-placeholder mt-3"
                      >
                        <ReCAPTCHA
                          ref={captchaRef}
                          sitekey={`6Lf2PHwqAAAAAMQDCVSSWU0NiyXAxeT1bt3MUJ0-`}
                          onChange={onCaptchaChange}
                        />
                      </Form.Group>
                      <div className="d-grid my-4 mb-2">
                        {/* <Button
                          type="submit"
                          className="btn-secondary btn btn-lg"
                          onClick={handleSubmit}
                        >
                          Login
                        </Button> */}

                        <Button
                          type="submit"
                          className="btn-secondary btn btn-lg"
                          onClick={handleSubmit}
                          disabled={loader}
                        >
                          {loader && (
                            <Spinner
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              animation="border"
                              variant="danger"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          Login
                        </Button>
                      </div>

                      <p className="text-2 text-muted text-center">
                        Don't have an account?{" "}
                        <a href="/register" className="text-danger">
                          Sign up
                        </a>
                      </p>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={6} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll"></div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        centered
        show={showUnverifiedPopup}
        onHide={handleCloseUnverifiedPopup}
      >
        <Modal.Header className="border-0 m-auto pb-0">
          <Modal.Title>
            <h1 className="text-12 mb-0 text-center">Email Unverified</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "35px" }}>
          <div className="text-center mb-3">
            <Image
              src={checkMailboxIcon}
              height={54}
              alt="Check mailbox icon"
            />
          </div>
          <h5 className="text-2 text-center mb-3">
            Please verify your mail id from the link sent on the registered
            email address
          </h5>
          <p
            className="text-secondary mb-3 d-flex justify-content-end"
            style={{ cursor: "pointer" }}
          >
            <a
              onClick={handleResendLink}
              className="fw-400 text-decoration-underline"
            >
              Resend Link
            </a>
          </p>
          <div className="note-area">
            <p className="text-secondary">
              <span className="text-danger fw-500">Note:</span> Please be
              patient with the time taken to receive the email as it can vary.
              If you can’t find the email from Dubai Chambers in your inbox,
              please check your “Junk/Spam” folder.
            </p>
          </div>
          <div className="d-grid mt-4">
            <Button
              onClick={handleCloseUnverifiedPopup}
              className="btn btn-secondary btn-lg fs-14"
            >
              Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showInvalidLoginPopup}
        onHide={() => setShowInvalidLoginPopup(false)}
      >
        {/* <Modal.Header className="border-0 m-auto pb-0">
          <Modal.Title>
            <h1 className="text-12 mb-0 text-center">
              Invalid username or password
            </h1>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{ padding: "35px" }}>
          <div className="text-center mb-3">
            <Image src={userIcon} height={25} alt="Check mailbox icon" />
          </div>
          <h5 className="text-2 text-center mb-3">
            {msg}
            <br />
          </h5>
          {/* <div className="note-area">
            <p className="text-secondary">
              <span className="text-danger fw-500">Note:</span> Please be
              patient with the time taken to receive the email as it can vary.
              If you can’t find the email from Dubai Chambers in your inbox,
              please check your “Junk/Spam” folder.
            </p>
          </div> */}
          <div className="d-flex justify-content-center mt-4">
            <Button
              onClick={() => setShowInvalidLoginPopup(false)}
              className="btn btn-secondary btn-lg fs-14"
            >
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
