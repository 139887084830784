import axios from "axios";
import {
  GET_USER_BY_ID_URL,
  LIST_USER_URL,
  GET_USER_BY_EMAIL_URL,
  CHANGEPASSWORD_URL,
  LIST_USER_BY_ROLE,
  DELETE_REPORT_FROM_SUBSCRIPTION,
  DELETE_PRODUCT_FROM_REPORT_SUBSRIPTION
} from "./apiConfig";
import authHeader from "./AuthHeader";
class UserService {
  getUserById(id) {
    return axios.post(GET_USER_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  getUserByEmail(email) {
    return axios
      .post(
        GET_USER_BY_EMAIL_URL,
        { email },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getUsers() {
    return axios
      .post(LIST_USER_BY_ROLE, { role: "MEMBER" }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  listUsers(pageSize, pageNo) {
    return axios.post(LIST_USER_URL, { pageSize, pageNo }).then((response) => {
      return response.data;
    });
  }

  changePassword(requestBody) {
    return axios
      .post(
        CHANGEPASSWORD_URL,
        {
          currentPassword: requestBody.currentPassword,
          newPassword: requestBody.newPassword,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      });
  }

  unsubscribeReport(requestBody) {
    return axios
      .post(DELETE_REPORT_FROM_SUBSCRIPTION, requestBody, { 
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  unsubsribeProductFromReport(requestBody) {
    return axios
      .post(DELETE_PRODUCT_FROM_REPORT_SUBSRIPTION, requestBody, { 
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new UserService();
