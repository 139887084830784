import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import { LIST_USER_BY_ROLE, CREATE_UPDATE_ADMIN, CREATE_UPDATE_USER, CHANGEUSERPASSWORD_URL } from "./apiConfig";

class AdminService {
  getAdminsUsers() {
    return axios
      .post(LIST_USER_BY_ROLE, { role: "ADMIN" }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  getSuperAdminsUsers() {
    return axios
      .post(LIST_USER_BY_ROLE, { role: "SUPERADMIN" }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createUpdateAdmin(ReqBody) {
    return axios
      .post(CREATE_UPDATE_ADMIN, ReqBody, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createUpdateUser(reqBody) {
    return axios
      .post(CREATE_UPDATE_USER, reqBody, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  changeUserPassword(reqBody) {
    return axios
      .post(CHANGEUSERPASSWORD_URL, reqBody, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AdminService();
