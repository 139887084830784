import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Modal, Table, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import keyIcon from "../assets/icons/key.png";
import userIcon from "../assets/icons/user.png";
import UserService from "../services/userService";
import { getUserByIdRequestBody } from "../request/requestBodies";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoutAndRedirect from "./LogoutAndRedirect";
import adminService from "../services/adminService";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import backIcon from "../assets/backIcon.png";
import deleteIcon from "../assets/icons/delete.png";
import subscriptionService from "../services/subscriptionService";
import userService from "../services/userService";
import Badge from 'react-bootstrap/Badge';

const ViewManagerUser = () => {


  const navigate = useNavigate();

  const { state } = useLocation();
  const [email, setEmail] = useState(state.email);
  const [user, setUser] = useState("");
  const [key, setKey] = useState("Account");
  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState(false);
  // State to store form field values
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [researchData, setResearchData] = useState([]);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formValues, setFormValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    memberNo: "",
    companyName: "",
    email: "",
    role: "",
    gender: "",
    createdBy: "",
    updatedBy: "",
    createdAt: "",
    updatedAt: "",
    status: "",
    failedLoginAttempts: "",
    lockTimestamp: "",
    accountNonLocked: "",
  });
  const [updatedReq, setUpdatedRequest] = useState({});

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorForProfile, setErrorForProfile] = useState({});
  const [isEditablePassword, setIsEditablePassword] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [expired, setExpired] = useState(false);
  const [accountLock, setAccountLock] = useState(false);
  const [enableAccount, setEnableAccount] = useState(false);
  const [genderStatus, setGenderStatus] = useState("MALE"); // Default is "MALE"

  const handleToggleGender = () => {
    setGenderStatus((prev) => (prev === "MALE" ? "FEMALE" : "MALE"));
  };

  const handleToggleEnableAccount = () => {
    setEnableAccount((prev) => !prev);
  };

  const handleToggleExpired = () => {
    setExpired((prev) => !prev);
  };

  const handleToggleAccountLock = () => {
    setFormIsDirty(true);
    setUpdatedRequest((prev) => ({
      ...prev,
      accountNonLocked: !prev.accountNonLocked,
    }));
  };

  const togglePasswordVisibility = (type) => {
    switch (type) {
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  // State to handle modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);

  // Handler for showing the modal
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleApplyClick = () => {
    setShowApplyModal(true);
  };

  // Handler for hiding the modal
  const handleModalCancel = () => {
    setShowDeleteModal(false);
  };

  const handleModalApplyCancel = () => {
    setShowApplyModal(false);
  };

  // Handler for confirming the action
  const handleDeleteConfirm = () => {
    setShowDeleteModal(false);
    // Add your delete logic here
    console.log("User deleted successfully");
  };

  // Handler for confirming the action
  const handleApplyConfirm = () => {
    setShowApplyModal(false);
    // Add your delete logic here
    console.log("User deleted successfully");
  };

  const handleToggle = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    // setIsActive(!isActive);
    // setFormValues({ ...formValues, status: !isActive });
    updateAdmin();
  };

  const updateAdmin = async () => {
    const reqBody = {
      ...formValues,
      status: formValues.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };

    // console.log("reqBody", reqBody);
    await adminService
      .createUpdateUser(reqBody)
      .then((res) => {
        console.log("res", res);
        if (res) {
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error occured!", {
          position: "top-center", // Use string directly
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 3000);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    console.log("emailId", email);
    const user = await UserService.getUserByEmail(email)
      .then(async (res) => {
        console.log("profile response", res);
        if (res && res.data && res.data) {
          setFormValues(res.data);
          setUpdatedRequest({ 
            accountNonLocked: res.data.accountNonLocked,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            gender: res.data.gender,
          });
          await subscriptionService
            .getSubscriptionByUserId(res.data.id)
            .then((apiResponse) => {
              if (
                apiResponse.statusCode === 200 &&
                apiResponse?.data?.subscriptionId !== 0
              ) {
                setSubscriptionId(apiResponse.data.subscriptionId);
                setReportData(apiResponse?.data?.reports);
                setResearchData(apiResponse?.data?.researchData);
              }
            })
            .catch((err) => { });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // State to manage whether the form is editable or not
  const [isEditable, setIsEditable] = useState(false);
  // Handle form input change
  const handleInputChange = (e) => {
    setFormIsDirty(true);
    const { name, value } = e.target;
    setUpdatedRequest({ ...updatedReq, [name]: value });
  };
  // Cancel form editing
  const handleCancelClick = () => {
    setIsEditable(false);
    navigate("/ManageAdmin");
  };
  // Save form changes
  const handleSaveClick = () => {
    console.log("Form submitted:", formValues);
    setIsEditable(false);
  };
  // Function to handle the toggle of the editable state
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handlePasswordChange = () => {
    if (newPassword === "") {
      errors.newPassword = "Enter new password";
    }
    if (confirmPassword === "") {
      errors.confirmPassword = "Reenter new password";
    }
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      errors.confirmPassword = "Password is not matching with new password";
    }
    if (!(errors.newPassword && errors.confirmPassword)) {
      adminService
        .changeUserPassword({
          email: formValues.email,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
        .then((res) => {
          toast.success("Password Changed successfully", {
            autoClose: 2000,
            position: "top-right",
          });
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          toast.error(
            "Encountered an error while changing password. Try again later",
            {
              autoClose: 2000,
              position: "top-right",
            }
          );
        });
    }
  };

  const unsubscribeReport = ({report, type}) => {
    console.log(`Unsubscribed report: ${report}, type: ${type}`);
    handleCloseDelModal(); // Close modal after unsubscribing
    userService
      .unsubscribeReport({
        userId: formValues.id,
        // subscriptionID: subscriptionId,
        ...(type === "REPORT"
          ? { reportID: report.id.toString() }
          : type === "RESEARCH"
            ? { researchDataID: report.id.toString() }
            : {}),
      })
      .then((response) => {
        setSelectedReport(null);
        toast.success(`Unsubscribed successfully`, {
          autoClose: 2000,
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 2000);
      })
      .catch((err) => {
        toast.error("Failed to unsubscribe. Try again later", {
          autoClose: 2000,
          position: "top-right",
        });
      });
  };

  const unsubscribeProductFromReport = (report, product) => {
    userService.unsubsribeProductFromReport({
      userId: formValues.id,
      reportID: report.id.toString(),
      productID: [product.id.toString()],
    })
      .then((response) => {
        toast.success("Successfully unsubscribed!", {
          position: "top-right",
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 2000);
      })
      .catch((error) => {
        toast.error("Unable to unsubscribe. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };

  const updateUser = async () => {
    const reqBody = {
      ...formValues,
      ...updatedReq,
    }
    await adminService
      .createUpdateUser(reqBody)
      .then((res) => {
        console.log("res", res);
        if (res) {
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error occured!", {
          position: "top-center", // Use string directly
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 3000);
      });
  };

  const handleSubmit = () => { };
  const [selectedProductForUnsubscribe, setSelectedProductForUnsubscribe] = useState(null);

  const [showUnsubscribeProduct, setShowUnsubscribeProduct] = useState(false);

  // State for tracking the selected product
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [selectedReport, setSelectedReport] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);

  const handleShowDltModal = () => {
    setShowDelModal(true);
  };

  const handleCloseDelModal = () => {
    setShowDelModal(false);
  };


  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content container">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a
                  onClick={() => {
                    navigate({ pathname: "/ManageUser" });
                  }}
                  className="fw-400"
                >
                  Manage Users
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="fw-700 no-link"
                style={{ cursor: "unset" }}
              >
                <strong className="fw-500">View User Profile</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Row className="align-items-center">
                        <Col lg={10}>
                          <div class="d-sm-flex align-items-center gap-x-4">
                            <div className="profile-name mb-2 mb-sm-0">
                              <span style={{ verticalAlign: "middle" }}>{`${formValues?.firstName?.charAt(0) || ""
                                }${formValues?.lastName?.charAt(0) || ""}`}</span>
                            </div>
                            <div>
                              <h5 class="text-base font-bold fw-500 ms-0 ms-sm-3">
                                {formValues?.firstName} {formValues?.lastName}
                              </h5>
                              <div className="d-sm-flex  align-items-center ms-0 ms-sm-3 mt-2">
                                <div className="ms-sm-0 mt-2 mt-sm-0">
                                  <div className="d-flex align-items-center toggle-switch">
                                    <Image
                                      style={{ width: "14px" }}
                                      src={userIcon}
                                      className="me-2"
                                    />
                                    <div className="toggle-wrap">
                                      <span
                                        className={
                                          formValues.status !== "ACTIVE"
                                            ? "inactive-text"
                                            : "active-text"
                                        }
                                      >
                                        Inactive
                                      </span>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={formValues.status === "ACTIVE"}
                                        onChange={handleToggle}
                                        className={
                                          formValues.status === "ACTIVE"
                                            ? "switch-active"
                                            : "switch-inactive"
                                        }
                                        disabled={
                                          email ===
                                          localStorage.getItem("email")
                                        }
                                      />
                                      <span
                                        className={
                                          formValues.status === "ACTIVE"
                                            ? "active-text"
                                            : "inactive-text"
                                        }
                                      >
                                        Active
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} style={{ textAlign: "right" }}>
                          <Button
                            style={{ fontSize: "14px" }}
                            onClick={handleDeleteClick}
                            className="btn btn-danger"
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Profile</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                First Name
                              </Form.Label>

                              <InputGroup className="mb-3 custom-group">
                                <FormControl
                                  value={updatedReq.firstName}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 30) {
                                      handleInputChange(e); // Only update if input length is <= 30
                                    }
                                  }}
                                  disabled={!isEditable}
                                  type="text"
                                  name="firstName"
                                  className={`custom-field-two custom-group-field ${isEditable
                                    ? "editable-field"
                                    : "non-editable-field"
                                    }`}
                                  style={{
                                    opacity: "0.6",
                                    fontSize: "14px",
                                    padding: "6px 16px 6px 0px",
                                  }}
                                  placeholder="First Name"
                                  maxLength={30} // Set max length to 30
                                  isInvalid={!!errorForProfile?.firstName}
                                />
                                <InputGroup.Text
                                  onClick={handleEditClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Image
                                    style={{ width: "14px" }}
                                    src={editIcon}
                                  />
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  {errorForProfile.firstName}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Last Name
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={updatedReq.lastName}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 30) {
                                        handleInputChange(e); // Only update if input length is <= 30
                                      }
                                    }}
                                    disabled={!isEditable}
                                    type="text"
                                    name="lastName"
                                    className={`custom-field-two custom-group-field ${isEditable
                                      ? "editable-field"
                                      : "non-editable-field"
                                      }`}
                                    style={{
                                      opacity: "0.6",
                                      fontSize: "14px",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    placeholder="Last Name"
                                    onFocus={(e) => {
                                      e.stopPropagation(); // Prevents event bubbling
                                    }}
                                    onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                                    maxLength={30} // Set max length to 30
                                    isInvalid={!!errorForProfile.lastName}
                                  />
                                  <InputGroup.Text
                                    onClick={(e) => {
                                      handleEditClick();
                                      e.stopPropagation(); // Prevents event bubbling
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="fw-400 me-2 fs-14">
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </span>
                                  </InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    {errorForProfile.lastName}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Gender
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <div className="d-sm-flex align-items-center ms-0 mt-2">
                                    <div className="ms-sm-0 mt-2 mt-sm-0">
                                      <div className="d-flex align-items-center toggle-switch">
                                        <Image
                                          style={{ width: "14px" }}
                                          src={userIcon}
                                          className="me-2"
                                          alt="User Icon"
                                        />
                                        <div className="toggle-wrap">
                                          {/* Label for Male */}
                                          <span className={"active-text"}>
                                            {updatedReq.gender}
                                          </span>
                                          {/* <Form.Check
                                            type="switch"
                                            id="gender-switch"
                                            checked={form === "MALE"}
                                            onChange={handleToggleGender}
                                            className={
                                              genderStatus === "MALE"
                                                ? "switch-active"
                                                : "switch-inactive"
                                            }
                                          /> */}
                                          {/* Label for Female */}
                                          {/* <span
                                            className={
                                              genderStatus === "FEMALE"
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            Female
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Email
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.email}
                                    type="text"
                                    name="email"
                                    disabled
                                    style={{
                                      opacity: "0.6",
                                      fontSize: "14px",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Phone Number
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.phoneNumber}
                                    type="text"
                                    name="phoneNumber"
                                    disabled
                                    style={{
                                      opacity: "0.6",
                                      fontSize: "14px",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Member ID
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.memberNo}
                                    type="text"
                                    name="memberNo"
                                    disabled
                                    style={{
                                      opacity: "0.6",
                                      fontSize: "14px",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Company Name
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.companyName}
                                    type="text"
                                    name="companyName"
                                    disabled
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.6",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            {/* <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Updated By
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.updatedBy}
                                    type="text"
                                    name="updatedBy"
                                    disabled
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.6",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col> */}
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Created Date
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={
                                      formValues.createdAt
                                        ? new Date(
                                          formValues.createdAt
                                        ).toLocaleDateString()
                                        : ""
                                    }
                                    type="text"
                                    name="createdDate"
                                    disabled
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.6",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Updated Date
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={
                                      formValues.updatedAt
                                        ? new Date(
                                          formValues.updatedAt
                                        ).toLocaleDateString()
                                        : ""
                                    }
                                    type="text"
                                    name="updatedDate"
                                    disabled
                                    style={{
                                      opacity: "0.6",
                                      fontSize: "14px",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Account</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            {/* <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Expired
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <div className="d-sm-flex align-items-center ms-0 mt-2">
                                    <div className="ms-sm-0 mt-2 mt-sm-0">
                                      <div className="d-flex align-items-center toggle-switch">
                                        <Image
                                          style={{ width: "14px" }}
                                          src={userIcon}
                                          className="me-2"
                                          alt="User Icon"
                                        />
                                        <div className="toggle-wrap">
                                          <span
                                            className={
                                              expired
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            true
                                          </span>
                                          <Form.Check
                                            type="switch"
                                            id="expired-switch"
                                            checked={expired}
                                            onChange={handleToggleExpired}
                                            className={
                                              expired
                                                ? "switch-active"
                                                : "switch-inactive"
                                            }
                                          />
                                          <span
                                            className={
                                              !expired
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            false
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            </Col> */}

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Account Lock
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <div className="d-sm-flex align-items-center ms-0 mt-2">
                                    <div className="ms-sm-0 mt-2 mt-sm-0">
                                      <div className="d-flex align-items-center toggle-switch">
                                        <Image
                                          style={{ width: "14px" }}
                                          src={userIcon}
                                          className="me-2"
                                          alt="User Icon"
                                        />
                                        <div className="toggle-wrap">
                                          <span className={
                                            updatedReq.accountNonLocked
                                            ? "active-text"
                                            : "inactive-text"
                                            }>
                                            false
                                          </span>
                                          <Form.Check
                                            type="switch"
                                            name="accountNonLocked"
                                            id="account-lock-switch"
                                            checked={!updatedReq.accountNonLocked}
                                            onChange={handleToggleAccountLock}
                                            className={
                                              !updatedReq.accountNonLocked
                                                ? "switch-active"
                                                : "switch-inactive"
                                            }
                                          />
                                          <span
                                            className={
                                              !updatedReq.accountNonLocked
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            true
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            </Col>

                            {/* <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Enable Account
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <div className="d-sm-flex align-items-center ms-0 mt-2">
                                    <div className="ms-sm-0 mt-2 mt-sm-0">
                                      <div className="d-flex align-items-center toggle-switch">
                                        <Image
                                          style={{ width: "14px" }}
                                          src={userIcon}
                                          className="me-2"
                                          alt="User Icon"
                                        />
                                        <div className="toggle-wrap">
                                          <span
                                            className={
                                              enableAccount
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            true
                                          </span>
                                          <Form.Check
                                            type="switch"
                                            id="enable-account-switch"
                                            checked={enableAccount}
                                            onChange={handleToggleEnableAccount}
                                            className={
                                              enableAccount
                                                ? "switch-active"
                                                : "switch-inactive"
                                            }
                                          />
                                          <span
                                            className={
                                              !enableAccount
                                                ? "active-text"
                                                : "inactive-text"
                                            }
                                          >
                                            false
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            </Col> */}

                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Failed Login Attempt
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.failedLoginAttempts}
                                    type="text"
                                    name="updatedDate"
                                    disabled
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.6",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Locked Till
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={
                                      formValues.lockTimestamp
                                        ? new Date(
                                          formValues.lockTimestamp
                                        ).toLocaleString()
                                        : ""
                                    }
                                    type="text"
                                    name="updatedDate"
                                    disabled
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.6",
                                      padding: "6px 16px 6px 0px",
                                    }}
                                    className="custom-group-field border-0 bg-white"
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Subscribed Reports</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Table bordered hover responsive className="mt-3">
                  <thead className="table">
                    <tr>
                      <th style={{ width: '10%' }} className="no-wrap-header">ID</th>
                      <th style={{ width: '30%' }} className="no-wrap-header">Name</th>
                      <th style={{ width: '50%' }} className="no-wrap-header">Filters</th>
                      <th style={{ width: '10%', textAlign: 'left' }} className="no-wrap-header">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length ? (
                      reportData.map((report, index) => {
                        return (
                          <tr key={index}>
                            <td>{report.id}</td>
                            <td>{report.reportName && report.reportName}</td>
                            <td style={{ wordWrap: 'break-word' }}>
                              {/* <div className="d-flex gap-1">
                                {report.product &&
                                  report.product.length ?
                                  report.product.map((product) => (
                                    <Badge className="d-flex align-items-center light-badge"

                                    >


                                      {product.productName}
                                      <Nav.Link
                                        onClick={(e) => { }}
                                        variant="link"
                                        className="text-danger"
                                      >
                                        <i class="fa-solid fa-xmark ms-2"
                                          onClick={() => setShowUnsubscribeProduct(true)}
                                        ></i>
                                        <>
                                          <Modal
                                            className="product-modal product-popup"
                                            show={showUnsubscribeProduct}
                                            // size="sm"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                            onHide={() => setShowUnsubscribeProduct(false)}
                                          >
                                            <Modal.Header className="pt-2 pb-2">Unsubscribe Report</Modal.Header>
                                            <Modal.Body className="ps-1 pe-2 pt-2 pb-2 overflow-hidden">
                                              <h5 className="text-2 text-center mb-3">
                                                Are you sure you want to unsubscribe?
                                              </h5>
                                              <div className="d-flex mt-4 gap-3 justify-content-center">
                                                <Button
                                                  onClick={() => {
                                                    setShowUnsubscribeProduct(false);
                                                  }}
                                                  className="btn btn-secondary btn-lg fs-14"
                                                >
                                                  Cancel
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    unsubscribeProductFromReport(report, product);
                                                  }}
                                                  className="btn btn-secondary btn-lg fs-14"
                                                >
                                                  Unsubscribe
                                                </Button>
                                              </div>
                                            </Modal.Body>
                                          </Modal></>
                                      </Nav.Link>

                                      
                                    </Badge>




                                  )) : ''}

                              </div> */}
                              <div className="d-flex gap-1 flex-wrap"
                                style={{
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                }}
                              >
                                {report.product && report.product.length
                                  ? report.product.map((product) => (
                                    <React.Fragment key={product.productName}>
                                      <Badge className="d-flex align-items-center light-badge text-truncate"
                                        style={{
                                          //maxWidth: '150px', Adjust badge max width
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}>
                                        {product.productName}
                                        <Nav.Link
                                          onClick={(e) => { }}
                                          variant="link"
                                          className="text-danger"
                                          style={{ marginLeft: '5px' }}
                                        >
                                          <i
                                            className="fa-solid fa-xmark ms-2"
                                            onClick={() => {
                                              setSelectedProduct(product); // Set the current product
                                              setShowUnsubscribeProduct(product.productName); // Show modal
                                            }}
                                          ></i>
                                        </Nav.Link>
                                      </Badge>

                                      {/* Modal Outside */}
                                      {showUnsubscribeProduct === product.productName && (
                                        <Modal
                                          className="product-modal product-popup"
                                          show={true}
                                          aria-labelledby="contained-modal-title-vcenter"
                                          centered
                                          onHide={() => setShowUnsubscribeProduct(null)}
                                        >
                                          <Modal.Header className="pt-2 pb-2">Unsubscribe Filter</Modal.Header>
                                          <Modal.Body className="ps-1 pe-2 pt-2 pb-2 overflow-hidden">
                                            <h5 className="text-2 text-center mb-3">
                                              Are you sure you want to unsubscribe?
                                            </h5>
                                            <div className="d-flex mt-4 gap-3 justify-content-center">
                                              <Button
                                                onClick={() => {
                                                  setShowUnsubscribeProduct(null);
                                                }}
                                                className="btn btn-secondary btn-lg fs-14"
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  unsubscribeProductFromReport(report, product);
                                                  setShowUnsubscribeProduct(null); // Hide modal after action
                                                }}
                                                className="btn btn-secondary btn-lg fs-14"
                                              >
                                                Unsubscribe
                                              </Button>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      )}
                                    </React.Fragment>
                                  ))
                                  : ''}
                              </div>
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {/* <Nav.Link
                                onClick={(e) => { }}
                                variant="link"
                                className="text-danger"
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={deleteIcon}
                                  onClick={() => {
                                    unsubscribeReport(report, "REPORT");
                                  }}
                                />
                              </Nav.Link> */}
                              <Nav.Link
                                variant="link"
                                className="text-danger"

                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={deleteIcon}
                                  onClick={() => {
                                    setSelectedReport({report, type: 'REPORT'});
                                    handleShowDltModal();
                                  }}
                                />
                              </Nav.Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Subscribed Research Data</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Table bordered hover responsive className="mt-3">
                  <thead className="table">
                    <tr>
                      <th style={{ width: '10%' }} className="no-wrap-header">ID</th>
                      <th className="no-wrap-header">Name</th>
                      <th style={{ width: '10%' }} className="no-wrap-header">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {researchData.length ? (
                      researchData.map((report, index) => {
                        return (
                          <tr key={index}>
                            <td>{report.id}</td>
                            <td>{report.fileName && report.fileName}</td>
                            <td>
                              <Nav.Link
                                onClick={(e) => { }}
                                variant="link"
                                className="text-danger"
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={deleteIcon}
                                  onClick={() => {
                                    setSelectedReport({report, type: "RESEARCH"});
                                    handleShowDltModal();
                                  }}
                                />
                              </Nav.Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Change Password</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={4} md={4}>
                      <Row>
                        <Col lg={12} md={12}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            New Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type={showNewPassword ? "text" : "password"}
                              name="newPassword"
                              className={`custom-field custom-group-field ${isEditablePassword
                                ? "editable-field"
                                : "non-editable-field"
                                }`}
                              onChange={(e) => {
                                e.preventDefault();
                                setNewPassword(e.target.value);
                              }}
                              isInvalid={!!errors.newPassword}
                              value={newPassword}
                            />
                            <InputGroup.Text
                              onClick={() => togglePasswordVisibility("new")}
                            >
                              {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.newPassword}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg={12} md={12}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Confirm Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              className={`custom-field custom-group-field ${isEditablePassword
                                ? "editable-field"
                                : "non-editable-field"
                                }`}
                              isInvalid={!!errors.confirmPassword}
                              onChange={(e) => {
                                e.preventDefault();
                                setConfirmPassword(e.target.value);
                              }}
                              value={confirmPassword}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                togglePasswordVisibility("confirm")
                              }
                            >
                              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmPassword}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg={4} md={4}>
                          <div className="mb-2">
                            <Button
                              style={{ fontSize: "14px" }}
                              className="btn"
                              onClick={handlePasswordChange}
                            >
                              Apply
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

          {formIsDirty && (
            <Row>
              <Col lg={12} className="add-product-footer-btn text-end">
                <div className="fixed-btn">
                  <Button className="btn-light me-2"
                    onClick={() => {
                      setUpdatedRequest({
                        accountNonLocked: formValues.accountNonLocked,
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        gender: formValues.gender,
                      });
                      setIsEditable(false);
                      setFormIsDirty(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={updateUser}
                    style={{ fontSize: "14px" }}
                    className="btn"
                  >
                    Apply Changes
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>

      <Modal
        className="product-modal product-popup"
        show={showDelModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseDelModal}
      >
        <Modal.Header className="pt-2 pb-2">
          Unsubscribe Report
        </Modal.Header>
        <Modal.Body className="ps-1 pe-2 pt-2 pb-2 overflow-hidden">
          <h5 className="text-2 text-center mb-3">
            Are you sure you want to unsubscribe?
          </h5>
          <div className="d-flex mt-4 gap-3 justify-content-center">
            <Button
              className="btn btn-secondary btn-lg fs-14"
              onClick={handleCloseDelModal}
            >
              No
            </Button>
            <Button
              className="btn btn-secondary btn-lg fs-14"
              onClick={(e) => { }}
            >
              <span
                className="text-white"
                style={{ width: "14px" }}
                onClick={() => {
                  unsubscribeReport(selectedReport, "REPORT");
                }}>Yes</span>

            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleModalCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            Confirm Submission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you want to delete user
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn btn-light" onClick={handleModalCancel}>
            No
          </Button>
          <Button
            className="btn"
            style={{ fontSize: "14px" }}
            onClick={handleDeleteConfirm}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            Confirm Submission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to set {formValues.firstName}{" "}
          {formValues.lastName}{" "}
          {formValues.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApplyModal} onHide={handleModalApplyCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            Confirm Submission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you want to apply changes
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn btn-light" onClick={handleModalApplyCancel}>
            No
          </Button>
          <Button
            className="btn"
            style={{ fontSize: "14px" }}
            onClick={handleApplyConfirm}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ViewManagerUser;
